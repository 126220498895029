.footer.has-cards {
    overflow: hidden;
    padding-top: 500px;
    margin-top: -420px;
    position: relative;
    background: transparent;
    pointer-events: none;
  }
  /* .footer.has-cards:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 600px;
    height: 2000px;
    background: #f4f5f7;
    transform: skew(0, -8deg);
  } */
  .footer.has-cards .container {
    pointer-events: auto;
    position: relative;
  }
  /* .container-lg {
    max-width: 1160px;
  } */
  .align-items-center {
    align-items: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .footer .copyright {
    font-size: 0.875rem;
  }
  .nav-footer .nav-link {
    font-size: 0.875rem;
  }
  .nav-footer .nav-item:last-child .nav-link {
    padding-right: 0;
  }
  .justify-content-end {
    justify-content: flex-end !important;
  }
  .fa-facebook-square:before {
    content: "\f082";
  }
  .btn-facebook {
    color: #fff;
    background-color: #3b5999;
    border-color: #3b5999;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  .btn-facebook:hover {
    color: #fff;
    background-color: #3b5999;
    border-color: #3b5999;
  }
  .btn-facebook:focus,
  .btn-facebook.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08),
      0 0 0 0 rgba(59, 89, 153, 0.5);
  }
  .btn-facebook.disabled,
  .btn-facebook:disabled {
    color: #fff;
    background-color: #3b5999;
    border-color: #3b5999;
  }
  .btn-facebook:not(:disabled):not(.disabled):active,
  .btn-facebook:not(:disabled):not(.disabled).active,
  .show > .btn-facebook.dropdown-toggle {
    color: #fff;
    background-color: #2d4474;
    border-color: #3b5999;
  }
  .btn-facebook:not(:disabled):not(.disabled):active:focus,
  .btn-facebook:not(:disabled):not(.disabled).active:focus,
  .show > .btn-facebook.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(59, 89, 153, 0.5);
  }
  .btn-facebook {
    color: #fff;
    background-color: #3b5999;
    border-color: #3b5999;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  .btn-facebook:hover {
    color: #fff;
    background-color: #3b5999;
    border-color: #3b5999;
  }
  .btn-facebook:focus,
  .btn-facebook.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08),
      0 0 0 0 rgba(59, 89, 153, 0.5);
  }
  .btn-facebook.disabled,
  .btn-facebook:disabled {
    color: #fff;
    background-color: #3b5999;
    border-color: #3b5999;
  }
  .btn-facebook:not(:disabled):not(.disabled):active,
  .btn-facebook:not(:disabled):not(.disabled).active,
  .show > .btn-facebook.dropdown-toggle {
    color: #fff;
    background-color: #2d4474;
    border-color: #3b5999;
  }
  .btn-facebook:not(:disabled):not(.disabled):active:focus,
  .btn-facebook:not(:disabled):not(.disabled).active:focus,
  .show > .btn-facebook.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(59, 89, 153, 0.5);
  }
  
  .photo_format_dsd {
    height: 35px;
  }
  
  .photo_format_aecom {
    height: 20px;
  }
  
  .photo_format_facebook {
    height: 35px;
  }
  
  .section-bg-grey {
    background-color: #ebeef3;
  }
  
  .section-transparent09 {
    opacity: 0.9;
  }
  
  .text-grey {
    color: #c2b1b1 !important;
  }
  
  .text-align-justify {
    text-align: justify;
  }
  
  .text-align-center {
    text-align: center;
  }
  
  .text-align-left {
    text-align: left;
  }
  
  .thick {
    font-weight: bold;
  }
  
  .img_center {
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
  }
  
  .bg_light {
    background: linear-gradient(rgba(250, 243, 243, 0.5), transparent);
    background-color: rgb(248, 237, 216); /*this your primary color*/
  }
  
  .section-bg-green-light{
    /* background-color: #00cc00 */
    background: rgba(0, 128, 0, 0.3) /* Green background with 30% opacity */
  }
  